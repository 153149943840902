<template>
  <div>
    <v-card class="mx-auto">
      <v-app-bar dark color="#19647E">
        <v-icon large class="mr-3">text_snippet</v-icon>
        <v-toolbar-title>Registration</v-toolbar-title>
      </v-app-bar>

      <v-card-text>
        <v-form ref="form">
          <v-text-field
            v-model="umUser.name"
            label="Name"
            :autofocus="true"
            :rules="[(v) => !!v || 'Name is required']"
          ></v-text-field>

          <v-autocomplete
            :items="countries"
            item-text="title"
            item-value="code"
            v-model="umUser.countryCode"
            label="Country"
            required
            :rules="[(v) => !!v || 'Country is required']"
          ></v-autocomplete>

          <v-text-field
            :prefix="umUser.countryCode"
            v-model="umUser.mobileNumber"
            label="Contact"
            required
            :rules="[(v) => !!v || 'Contact Number is required']"
          ></v-text-field>

          <v-text-field
            v-model="umUser.email"
            label="Email"
            required
            :rules="emailRules"
          ></v-text-field>

          <v-text-field
            v-model="umUser.password"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            :type="showPassword ? 'text' : 'password'"
            label="Password"
            :rules="passwordRules"
            required
            @click:append="showPassword = !showPassword"
          ></v-text-field>

          <v-text-field
            v-model="dbUser.vendorCodes"
            label="Code"
            :rules="[(v) => !!v || 'Code is required']"
          ></v-text-field>
        </v-form>

        <v-btn @click="signUp()" class="mt-5" dark color="#19647E"
          >Create Account</v-btn
        >
        <v-btn
          @click="$router.push({ name: 'Login' })"
          class="mt-5"
          text
          medium
          dark
          color="#19647E"
          >Sign in instead</v-btn
        >
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DataServiceVD from "./../services/dataServiceVD.js";
const dataServiceVD = new DataServiceVD();

export default {
  data() {
    return {
      dbUser: {
        roles: ["VENDOR"],
      },
      umUser: { type: "register" },
      countries: [],
      showPassword: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      mobileNumberRules: [(value) => !!value || "Mobile number required."],
      passwordRules: [
        (value) => !!value || "Password required.",
        (value) => (value || "").length >= 8 || `Min 8 characters`,
      ],
    };
  },
  mounted() {
    dataServiceVD.getConfig("countries").then((r) => {
      this.countries = r.data;
    });
  },
  methods: {
    signUp() {
      if (this.$refs.form.validate()) {
        this.$setLoader();
        dataServiceVD
          .createUser({ ...this.umUser, ...this.dbUser })
          .then(() => {
            this.$store.state.showDialogMessage = true;
            this.$store.state.dialogMessageTitle =
              "Account Changed Successfully";
            this.$store.state.dialogMessageText =
              "Your account has been changed successfully. Please inform InsightzClub account manager to activate your account.";
            this.$disableLoader();
          });
      }
    },
  },
};
</script>